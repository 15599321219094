<template>
  <div class="legal-content">
    <component :is="component" v-if="component" />
    <span v-if="component === undefined">Not found</span>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

const loadContent = async (legalType, locale) => {
  let component = import(/* webpackChunkName: '' */ `./${legalType}/${locale}.md`)
    .then((m) => m.default || m)
    .catch(() => import(/* webpackChunkName: '' */ `./${legalType}/en.md`))
    .then((m) => m.default || m)
    .catch((e) => console.log(e));

  return component;
};

export default {
  name: "LegalContent",

  data: () => ({
    component: null,
  }),

  computed: {
    ...get("lang", ["locale"]),

    legalType() {
      return this.$route.name.split(".")[1];
    },
  },

  watch: {
    async locale(locale) {
      const component = await loadContent(this.legalType, locale);
      this.component = component;
    },
  },

  async created() {
    const component = await loadContent(this.legalType, this.locale);
    this.component = component;
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  h1 {
    font-weight: 500;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 10px;
    font-weight: 500;
  }
}
</style>